import http from "../utils/http"

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function payment(params) {
    return http({
        url: "/seckill/api/ordercreate/payment",
        data: params,
        forceLogin: true
    })
}

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function calculate(params) {
    return http({
        url: "/seckill/api/ordercreate/calculate",
        data: params,
        forceLogin: true
    })
}

/**
 * รับการเข้าถึงการตรวจสอบรายชื่อ Lightning Deal
 * @param {object} params
 */
export function evaluateConfig() {
    return http({
        url: "/api/goodsevaluate/config",
        data: {},
        forceLogin: true
    })
}

/**
 * การสร้างคําสั่งซื้อ
 * @param {object} params
 */
export function orderCreate(params) {
    return http({
        url: "/seckill/api/ordercreate/create",
        data: params,
        forceLogin: true
    })
}

/**
 * รายการสินค้า
 * @param {object} params
 */
export function goodsPage(params) {
    return http({
        url: "/seckill/api/seckillgoods/page",
        data: params
    })
}

/**
 * รายละเอียดสินค้า
 * @param {object} params
 */
export function goodsSkuDetail(params) {
    return http({
        url: "/seckill/api/seckillgoods/detail",
        data: params
    })
}

/**
 * ระยะเวลาการขายสายฟ้าแลบ
 * @param {object} params
 */
export function timeList(params) {
    return http({
        url: "/seckill/api/seckill/lists",
        data: params
    })
}
