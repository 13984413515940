<template>
	<div class="goods-detail">
		
		
		<div data-v-eea730d8="" class="search_bread" style="border-bottom: 1px solid rgb(239 239 244 / 0%); padding: 0px 10px 15px 10px; "><a data-v-eea730d8="" href="/web/" class="router-link-active">หน้าแรก</a><span data-v-eea730d8="" class="padd-10" style=" padding: 0 10px;">&gt;</span>
		
		<router-link :to="{ path: '/list', query: { category_id: c1} }">{{catewords}}</router-link>
			
		
		<span data-v-eea730d8="" class="padd-10" style="padding: 0 10px;" >&gt;</span> <a class="router-link-exact-active router-link-active" style="font-weight: 900; font-family: 'sukhumvittadmai2';     font-weight: 600; " >{{ goodsSkuDetail.sku_name }}</a> <!----><!----></div>
		
		
		
		
		
		
		
		
		
		
		
		
		
		<div class="preview-wrap">
			<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
				<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true" :options="playerOptions"
				 @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
				 @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
				 @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @statechanged="playerStateChanged($event)"
				 @ready="playerReadied"></video-player>

				<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
					<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">วีดีโอ</span>
					<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">ภาพ</span>
				</div>
			</div>
			<div class="magnifier-wrap">
				<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
			</div>

			<div class="spec-items">
				<span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
				<ul :style="{ left: 30 + thumbPosition + 'px' }">
					<!-- รูปขนาดย่อของผลิตภัณฑ์ -->
					<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
						<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
					</li>
				</ul>
			</div>

			<div class="share-collect">
				<div @click="editCollection">
					<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
					<span data-collects="0">ชืนชอบ（{{ goodsSkuDetail.collect_num }}）</span>
				</div>
				<div v-if="kefuConfig.system == 0 && kefuConfig.open_pc == 1">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0"><a :href="kefuConfig.open_url" target="_blank">ติดต่อฝ่ายบริการลูกค้า</a></span>
				</div>
				<div @click="service_link" v-else-if="kefuConfig.system == 1">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0">ติดต่อฝ่ายบริการลูกค้า</span>
				</div>
			</div>
		</div>

		<!-- ข้อมูลผลิตภัณฑ์ -->
		<div class="basic-info-wrap" v-loading="loading" style="    font-weight: 500;
    font-size: 22px; ">
			<h1 style="       font-size: 23px;
    font-family: 'sukhumvittadmai2'; ">{{ goodsSkuDetail.sku_name }}</h1>
		



<div style="margin-top: 10px;">
	<div class="hO9GFq">
		<svg viewBox="0 0 108 21" height="21" width="108" class="flash-sale-logo flash-sale-logo--white">
			<g fill="currentColor" fill-rule="evenodd">
				<path d="M0 16.195h3.402v-5.233h4.237V8H3.402V5.037h5.112V2.075H0zm29.784 0l-.855-2.962h-4.335l-.836 2.962H20.26l4.723-14.12h3.576l4.724 14.12zM26.791 5.294h-.04s-.31 1.54-.563 2.43l-.797 2.744h2.74l-.777-2.745c-.252-.889-.563-2.43-.563-2.43zm7.017 9.124s1.807 2.014 5.073 2.014c3.13 0 4.898-2.034 4.898-4.384 0-4.463-6.259-4.147-6.259-5.925 0-.79.778-1.106 1.477-1.106 1.672 0 3.071 1.245 3.071 1.245l1.439-2.824s-1.477-1.6-4.47-1.6c-2.76 0-4.918 1.718-4.918 4.325 0 4.345 6.258 4.285 6.258 5.964 0 .85-.758 1.126-1.457 1.126-1.75 0-3.324-1.462-3.324-1.462zm12.303 1.777h3.402v-5.53h5.054v5.53h3.401V2.075h-3.401v5.648h-5.054V2.075h-3.402zm18.64-1.678s1.692 1.915 4.763 1.915c2.877 0 4.548-1.876 4.548-4.107 0-4.483-6.492-3.871-6.492-6.36 0-.987.914-1.678 2.08-1.678 1.73 0 3.052 1.224 3.052 1.224l1.088-2.073s-1.4-1.501-4.12-1.501c-2.644 0-4.627 1.738-4.627 4.068 0 4.305 6.512 3.87 6.512 6.379 0 1.145-.952 1.698-2.002 1.698-1.944 0-3.44-1.48-3.44-1.48zm19.846 1.678l-1.166-3.594h-4.84l-1.166 3.594H74.84L79.7 2.174h2.623l4.86 14.021zM81.04 4.603h-.039s-.31 1.382-.583 2.172l-1.224 3.752h3.615l-1.224-3.752c-.253-.79-.545-2.172-.545-2.172zm7.911 11.592h8.475v-2.192H91.46V2.173H88.95zm10.477 0H108v-2.192h-6.064v-3.772h4.645V8.04h-4.645V4.366h5.753V2.174h-8.26zM14.255.808l6.142.163-3.391 5.698 3.87 1.086-8.028 12.437.642-8.42-3.613-1.025z"></path>
			</g>
		</svg>
		<svg height="20" viewBox="0 0 20 20" width="20" class="shopee-svg-icon _8tjJ2v">
			<g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="1.8" transform="translate(1 1)">
				<circle cx="9" cy="9" r="9"></circle>
				<path d="m11.5639648 5.05283203v4.71571528l-2.72832027 1.57129639" stroke-linecap="round" stroke-linejoin="round" transform="matrix(-1 0 0 1 20.39961 0)"></path>
			</g>
		</svg>
		<div class="FteIo2" style="font-weight: 500;" >จบใน</div>
		<div class="module-20-time">
		<count-down
						class="count-down"
						v-on:start_callback="countDownS_cb()"
						v-on:end_callback="countDownE_cb()"
						:currentTime="seckillTimeMachine.currentTime"
						:startTime="seckillTimeMachine.startTime"
						:endTime="seckillTimeMachine.endTime"
						:dayTxt="'：'"
						:hourTxt="'：'"
						:minutesTxt="'：'"
						:secondsTxt="''"
					></count-down>
		</div>
	
		
		
		
		
		
		
		
		
		
			</div>
	<div class="flex flex-column">
		<div class="flex flex-column LR5fT5">
			<div class="flex items-center">
				<div class="flex items-center _9GxTz3">
					<div class="Kg2R-S" style="font-weight: 900; font-family: 'sukhumvittadmai2'; " >฿{{ goodsSkuDetail.price }}</div>
					<div class="flex items-center">
						<div class="X0xUb5" style="font-weight: 900; font-family: 'sukhumvittadmai2'; ">฿{{ goodsSkuDetail.seckill_price }}</div>
						<div class="+1IO+x" stle="    font-family: 'sukhumvittadmai2';">{{(1-(goodsSkuDetail.seckill_price/goodsSkuDetail.price))*100 }}% ส่วนลด</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



			<div class="discount-banner ns-bg-color" v-if="seckillTimeMachine.currentTime" style="display:none;">
				<div class="activity-name"><i class="discount-icon iconfont iconicon_naozhong"></i><span>เวลาจํากัดพุ่งสูงขึ้น</span></div>
				<div class="surplus-time">
					<span>{{ seckillText }}</span>
					<count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
					 :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
					 :dayTxt="'ฟ้า'" :hourTxt="'ชั่วโมง'" :minutesTxt="'นาที'" :secondsTxt="'ที่สอง'"></count-down>
				</div>
			</div>

			<div class="item-block" style="    margin-top: -0px;">
				<div class="promotion-price">
					

					<div class="statistical" style="    margin-top: -90px;">
						<ul>
							<li>
								<p style="    font-family: sukhumvittadmai2; margin: 0.2rem 0;">รีวิวสินค้า</p>
								<span>{{ goodsSkuDetail.evaluate }}</span>
							</li>
							<li>
								<p style="    font-family: sukhumvittadmai2; margin: 0.2rem 0;" >ยอดขาย</p>
								<span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
							</li>
						</ul>
					</div>

					
				</div>
			</div>
			<dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
				<dt>จัดส่ง</dt>
				<dd>
					<i class="i-activity-flag ns-text-color ns-border-color" v-if="goodsSkuDetail.is_free_shipping">จัดส่งฟรี</i>
					<i class="i-activity-flag ns-text-color ns-border-color" v-else>การจัดส่งแบบปกติ</i>
				</dd>
			</dl>
			<dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
				<dt>สถานที่</dt>
				<dd>
					<div class="region-selected ns-border-color-gray">
						<span>
							<template v-if="selectedAddress['level_1']">
								<template v-for="item in selectedAddress">
									{{ item.name }}
								</template>
							</template>
							<template v-else>
								โปรดเลือกที่อยู่จัดส่ง
							</template>
						</span>
						<i class="el-icon-arrow-down"></i>
					</div>

					<div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
						<ul class="nav-tabs">
							<li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
								<div>
									<span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : 'โปรดเลือกจังหวัด' }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
								<div>
									<span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : 'โปรดเลือกเมือง' }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
								<div>
									<span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : 'โปรดเลือกโซน/เขต' }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
								<ul class="province">
									<li v-for="(item, index) in provinceArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('city', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
								<ul class="city">
									<li v-for="(item, index) in cityArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('district', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
								<ul class="district">
									<li v-for="(item, index) in districtArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('community', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="item-line service">
				<dt>บริการเสริม</dt>
				<dd>
					<span>
						เรา <span class="ns-text-color">{{siteInfo.site_name}}</span> บริการจัดส่งและบริการหลังการขาย
					</span>
				</dd>
			</dl>
			<hr class="divider" />
			<div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
				<dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
					<dt>{{ item.spec_name }}</dt>
					<dd>
						<ul>
							<li v-for="(item_value, index_value) in item.value" :key="index_value">
								<!-- <div :class="{
                                        'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                        disabled: item_value['disabled'] || !item_value['selected']
                                    }"> -->
								<div :class="{
																				'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
																				disabled: item_value['disabled']
																		}"
																		@click="changeSpec(item_value.sku_id, item_value.spec_id, item_value.disabled)">
									<img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
									<span>{{ item_value.spec_value_name }}</span>
									<i class="iconfont iconduigou1 ns-text-color"></i>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>

			<div class="buy-number">
				<dl class="item-line">
					<dt>ปริมาณ</dt>
					<dd>
						<div class="num-wrap">
							<el-input v-model="number" placeholder="0" @input="keyInput(false)" @blur="blur"></el-input>
							<div class="operation">
								<span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
								<span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
							</div>
						</div>
						<span class="unit">{{ goodsSkuDetail.unit }}</span>
						<span class="inventory">เหลือสินค้า{{ goodsSkuDetail.stock }}{{ goodsSkuDetail.unit }}</span>
					</dd>
				</dl>
			</div>

			<dl class="item-line buy-btn">
				<dt></dt>
				<dd v-if="goodsSkuDetail.goods_state == 1">
					<template>
						<el-button type="primary" plain @click="buyNow" v-if="goodsSkuDetail.stock > 0" style="    font-weight: 700; font-family: 'sukhumvittadmai2'; " >ซื้อเลย</el-button>
						<el-button type="info" plain v-else disabled>สินค้าคงคลังไม่เพียงพอ</el-button>
					</template>
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="รูปภาพรหัส QR" /></div>
					</div>
				</dd>
				<dd v-else>
					<template>
						<el-button type="info" style="    font-weight: 700; font-family: 'sukhumvittadmai2'; "  plain disabled>สินค้าไม่มีแล้ว</el-button>
					</template>
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="รูปภาพรหัส QR" /></div>
					</div>
				</dd>
			</dl>
			<!-- shopInfo.shop_baozh == 1 || shopInfo.shop_qtian == 1 || shopInfo.shop_zhping == 1 || shopInfo.shop_erxiaoshi == 1 || shopInfo.shop_tuihuo == 1 || shopInfo.shop_shiyong == 1 || shopInfo.shop_shiti == 1 || shopInfo.shop_xiaoxie == 1 -->
			<dl class="item-line merchant-service" v-show="service_list.length">
				<dt>สินค้าและบริการ</dt>
				<div>
					<dd v-for="item in service_list">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="item.service_name">{{item.service_name}}</span>
					</dd>
				</div>
			</dl>
		</div>

		<!-- ข้อมูลร้าน -->
		<!-- <div class="shop-wrap">
			<div class="head-wrap">
				<div class="img-wrap">
					<img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
					 @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
				</div>
				<h5>
					<span class="site-name">{{ shopInfo.site_name }}</span>
					<el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">ดําเนินการด้วยตนเอง</el-tag>
				</h5>
			</div>
			<div class="info-wrap">
				<dl>
					<dt class="site-score">การให้คะแนนร้านค้า</dt>
					<dd>
						<el-rate v-model="score" disabled></el-rate>
					</dd>
					<dt>รายละเอียดสินค้า：</dt>
					<dd><span>{{ shopInfo.shop_desccredit }}</span>แบ่ง</dd>
					<dt>บริการสําหรับผู้ขาย：</dt>
					<dd><span>{{ shopInfo.shop_servicecredit }}</span>แบ่ง</dd>
					<dt>ความเร็วในการจัดส่ง：</dt>
					<dd><span>{{ shopInfo.shop_deliverycredit }}</span>แบ่ง</dd>
				</dl>
			</div>
			<div class="info-wrap" v-if="shopInfo.telephone">
				<dl>
					<dt>เบอร์ติดต่อ：</dt>
					<dd>{{ shopInfo.telephone }}</dd>
				</dl>
			</div>
			<div class="operation">
				<el-button class="btn btn-default" size="medium" @click="$router.pushToTab('/shop-' + shopInfo.site_id)">เข้ามาและเดินไปรอบ ๆ</el-button>
				<el-button size="medium" @click="follow" v-if="hasFollow">เลิกติดตาม</el-button>
				<el-button size="medium" @click="follow" v-else>ติดตามร้านค้าของคุณ</el-button>
			</div>
		</div> -->

		<div class="detail-wrap">
			

			<el-tabs class="goods-tab" v-model="tabName"  @tab-click="tabChange">
				<el-tab-pane label="รายละเอียดสินค้า" name="detail">
					<div v-html="goodsSkuDetail.goods_content"></div>
					
					
					<div class="empty animate__animated animate__fadeIn" v-if="goodsSkuDetail.goods_content==''" style="color: #898989;
					    text-align: center;
					    font-size: 14px;"><div class="el-empty" data-v-1ab1ffd8="">
					   <div class="el-empty__image" style="width: 200px;">
					     <img src="https://i.imgur.com/VrthEHO.png">
					   </div>
					   <div class="el-empty__description">
					      <p style="font-family: 'sukhumvittadmai';">ไม่มีรายละเอียดสินค้า</p>
					   </div>
					   <!--v-if-->
					</div></div>
					
					
				</el-tab-pane>
				<el-tab-pane label="แอตทริบิวต์ผลิตภัณฑ์" name="attr">
					<ul class="attr-list">
						<template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
							<li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index">{{ item.attr_name }}：{{ item.attr_value_name }}</li>
						</template>
						
					</ul>
					
					<div class="empty  animate__animated animate__fadeIn" v-if="goodsSkuDetail.goods_attr_format.length == 0" style="color: #898989;
					    text-align: center;
					    font-size: 14px;"><div class="el-empty" data-v-1ab1ffd8="">
					   <div class="el-empty__image" style="width: 200px;">
					    <img src="https://i.imgur.com/VrthEHO.png">
					   </div>
					   <div class="el-empty__description">
					      <p style="font-family: 'sukhumvittadmai';">ไม่มี แอตทริบิวต์ผลิตภัณฑ์ นี้</p>
					   </div>
					   <!--v-if-->
					</div></div>
					
					
					
					
					
				</el-tab-pane>
				<el-tab-pane v-if="evaluate_show" :label="goodsEvaluateList.length ? 'รีวิวสินค้า(' + goodsEvaluateList.length + ')' : 'รีวิวสินค้า'"
				 name="evaluate" class="evaluate">
					<template v-if="goodsEvaluateList.length">
						<nav>
							<li :class="evaluaType == 0 ? 'selected' : ''" @click="evaluationType(0)">รีวิวทั้งหมด({{evaluteCount.total}})</li>
							<li :class="evaluaType == 1 ? 'selected' : ''" @click="evaluationType(1)">ยอดเยี่ยม({{evaluteCount.haoping}})</li>
							<li :class="evaluaType == 2 ? 'selected' : ''" @click="evaluationType(2)">ปลานกลาง({{evaluteCount.zhongping}})</li>
						  <li :class="evaluaType == 3 ? 'selected' : ''" @click="evaluationType(3)">ไม่ค่อยดี({{evaluteCount.chaping}})</li>
						</nav>
						<ul class="list">
							<li v-for="(item, index) in goodsEvaluateList" :key="index">
								<div class="member-info">
									<img v-if="item.member_headimg==''" :src="'https://www.pngarts.com/files/11/Avatar-PNG-Download-Image.png'" @error="imageErrorEvaluate(index)" class="avatar" />
									<img v-else :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)" class="avatar" />
									<span>{{ item.member_name }}</span>
								</div>
								<div class="info-wrap">
									<el-rate v-model="item.star" disabled></el-rate>
									<p class="content">{{ item.content }}</p>
									<div class="img-list" v-if="item.images">
										<el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)" :preview-src-list="item.imagesFormat"></el-image>
									</div>
									<div class="sku-info">
										<span>{{ item.sku_name }}</span>
										<span class="create-time">เวลาที่รีวิว :{{ $util.timeStampTurnTime(item.create_time) }}</span>
									</div>
									<div class="evaluation-reply" v-if="item.explain_first != ''">ร้านค้าตอบ：{{ item.explain_first }}</div>
									<template v-if="item.again_content != ''">
										<div class="review-evaluation">
											<span>การประเมินเพิ่มเติม</span>
											<span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
										</div>
										<p class="content">{{ item.again_content }}</p>
										<div class="img-list">
											<el-image v-for="(again_img, again_index) in item.again_images" :key="again_index" :src="$img(again_img)"
											 :preview-src-list="item.againImagesFormat"></el-image>
										</div>
										<div class="evaluation-reply" v-if="item.again_explain != ''">ร้านค้าตอบ：{{ item.again_explain }}</div>
									</template>
								</div>
							</li>
						</ul>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" prev-text="ก่อน" next-text="ต่อไป" :current-page.sync="currentPage"
							 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
							 hide-on-single-page></el-pagination>
						</div>
					</template>
					<div class="empty animate__animated animate__fadeIn" v-else>  <div class="el-empty" data-v-1ab1ffd8="">
					   <div class="el-empty__image" style="width: 200px;">
					      <img src="https://i.imgur.com/VrthEHO.png">
					   </div>
					   <div class="el-empty__description">
					      <p style="font-family: 'sukhumvittadmai';">ยังไม่มีรีวิวสินค้านี้</p>
					   </div>
					   <!--v-if-->
					</div>
					</div>
				</el-tab-pane>
				<template v-if="service">
					<el-tab-pane v-if="service.is_display == 1" label="การป้องกันหลังการขาย" name="after_sale" class="after-sale"><div v-html="service.content"></div></el-tab-pane>
				</template>
			</el-tabs>
		</div>
		<div class="goods-recommended">
						<goods-recommend />
					</div>
		<!--ติดต่อหน้าต่างป็อปอัพบริการลูกค้า-->
		<servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:shopInfo.site_id,logo:shopInfo.logo,shop_name:shopInfo.site_name}"></servicerMessage>
	</div>
</template>

<script>
	import PicZoom from "vue-piczoom"
	import detail from "./detail_seckill.js"
	import GoodsRecommend from "@/components/GoodsRecommend"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		name: "seckill_detail",
		components: {
			PicZoom,
			GoodsRecommend,
			servicerMessage
		},
		mixins: [detail],

	}
</script>
<style lang="scss">
	@import "./detail_seckill.scss";
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	svg:not(:root) {
		overflow: hidden;
	}
	
	.flex {
		display: flex;
	}
	
	.flex-column {
		flex-direction: column;
	}
	
	.items-center {
		align-items: center;
	}
	
	.shopee-svg-icon {
		display: inline-block;
		width: 1em;
		height: 1em;
		fill: currentColor;
		position: relative;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/homepage/pcmall-homepage.a1e800ee1e859a5df100.css */
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/homepage/HomeOfficialShopSection.ec62e6cc621fd63669d7.css */
	
	.flex {
		display: flex;
	}
	
	.flex-column {
		flex-direction: column;
	}
	
	.items-center {
		align-items: center;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/flashsale/426.a1cfaea72a580ba6d387.legacy.css */
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	.shopee-countdown-timer {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		cursor: default;
		display: flex;
		color: #000;
		align-items: center;
	}
	
	.shopee-countdown-timer__colon {
		display: flex;
		flex-direction: column;
		background-position-y: 3px;
		text-align: center;
		width: 3px;
		height: 17px;
		font-weight: 400;
		font-size: 20px;
		margin: 0 2px;
	}
	
	.shopee-countdown-timer__colon--flashing-off {
		opacity: 0;
	}
	
	.shopee-countdown-timer__number {
		background: currentColor;
		border-radius: 2px;
		height: 17px;
		margin-bottom: 4px;
		padding: 1px 3px;
		line-height: 20px;
		font-size: 19px;
		overflow: hidden;
		display: flex;
		justify-content: space-around;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		min-width: 20px;
		text-align: center;
	}
	
	.shopee-countdown-timer__number__deca,
	.shopee-countdown-timer__number__hexa {
		display: inline-block;
		width: 9px;
		height: 187px;
		overflow: hidden;
	}
	
	.shopee-countdown-timer__number__deca--second {
		-webkit-animation-name: second-digit;
		animation-name: second-digit;
		-webkit-animation-duration: 10s;
		animation-duration: 10s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--second {
		-webkit-animation-name: second-ten;
		animation-name: second-ten;
		-webkit-animation-duration: 60s;
		animation-duration: 60s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--minute {
		-webkit-animation-name: minute-digit;
		animation-name: minute-digit;
		-webkit-animation-duration: 600s;
		animation-duration: 600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--minute {
		-webkit-animation-name: minute-ten;
		animation-name: minute-ten;
		-webkit-animation-duration: 3600s;
		animation-duration: 3600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--hour {
		-webkit-animation-name: hour-digit;
		animation-name: hour-digit;
		-webkit-animation-duration: 36000s;
		animation-duration: 36000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--hour {
		-webkit-animation-name: hour-ten;
		animation-name: hour-ten;
		-webkit-animation-duration: 360000s;
		animation-duration: 360000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__item {
		position: relative;
		width: 9px;
		height: 17px;
	}
	
	.shopee-countdown-timer__number__item svg {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 17px;
		height: 13px;
		fill: #fff;
		stroke: #fff;
		overflow: visible;
	}
	
	.colon-dot {
		position: absolute;
		border-radius: 100%;
		width: 3px;
		height: 3px;
		top: 10%;
		left: 0;
		background: currentColor;
	}
	
	.colon-dot__wrapper {
		width: 100%;
		height: 50%;
		position: relative;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/flashsale/426.a1cfaea72a580ba6d387.legacy.css */
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	.shopee-countdown-timer {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		cursor: default;
		display: flex;
		color: #000;
		align-items: center;
	}
	
	.shopee-countdown-timer__colon {
		display: flex;
		flex-direction: column;
		background-position-y: 3px;
		text-align: center;
		width: 3px;
		height: 17px;
		font-weight: 400;
		font-size: 20px;
		margin: 0 2px;
	}
	
	.shopee-countdown-timer__colon--flashing-off {
		opacity: 0;
	}
	
	.shopee-countdown-timer__number {
		background: currentColor;
		border-radius: 2px;
		height: 17px;
		margin-bottom: 4px;
		padding: 1px 3px;
		line-height: 20px;
		font-size: 19px;
		overflow: hidden;
		display: flex;
		justify-content: space-around;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		min-width: 20px;
		text-align: center;
	}
	
	.shopee-countdown-timer__number__deca,
	.shopee-countdown-timer__number__hexa {
		display: inline-block;
		width: 9px;
		height: 187px;
		overflow: hidden;
	}
	
	.shopee-countdown-timer__number__deca--second {
		-webkit-animation-name: second-digit;
		animation-name: second-digit;
		-webkit-animation-duration: 10s;
		animation-duration: 10s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--second {
		-webkit-animation-name: second-ten;
		animation-name: second-ten;
		-webkit-animation-duration: 60s;
		animation-duration: 60s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--minute {
		-webkit-animation-name: minute-digit;
		animation-name: minute-digit;
		-webkit-animation-duration: 600s;
		animation-duration: 600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--minute {
		-webkit-animation-name: minute-ten;
		animation-name: minute-ten;
		-webkit-animation-duration: 3600s;
		animation-duration: 3600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--hour {
		-webkit-animation-name: hour-digit;
		animation-name: hour-digit;
		-webkit-animation-duration: 36000s;
		animation-duration: 36000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--hour {
		-webkit-animation-name: hour-ten;
		animation-name: hour-ten;
		-webkit-animation-duration: 360000s;
		animation-duration: 360000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__item {
		position: relative;
		width: 9px;
		height: 17px;
	}
	
	.shopee-countdown-timer__number__item svg {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 17px;
		height: 13px;
		fill: #fff;
		stroke: #fff;
		overflow: visible;
	}
	
	.colon-dot {
		position: absolute;
		border-radius: 100%;
		width: 3px;
		height: 3px;
		top: 10%;
		left: 0;
		background: currentColor;
	}
	
	.colon-dot__wrapper {
		width: 100%;
		height: 50%;
		position: relative;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/pcmall-productdetailspage.ff0b3ccfbbc39630bc05.legacy.css */
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	.shopee-countdown-timer {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		cursor: default;
		display: flex;
		color: #000;
		align-items: center;
	}
	
	.shopee-countdown-timer__colon {
		display: flex;
		flex-direction: column;
		background-position-y: 3px;
		text-align: center;
		width: 3px;
		height: 17px;
		font-weight: 400;
		font-size: 20px;
		margin: 0 2px;
	}
	
	.shopee-countdown-timer__colon--flashing-off {
		opacity: 0;
	}
	
	.shopee-countdown-timer__number {
		background: currentColor;
		border-radius: 2px;
		height: 17px;
		margin-bottom: 4px;
		padding: 1px 3px;
		line-height: 20px;
		font-size: 19px;
		overflow: hidden;
		display: flex;
		justify-content: space-around;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		min-width: 20px;
		text-align: center;
	}
	
	.shopee-countdown-timer__number__deca,
	.shopee-countdown-timer__number__hexa {
		display: inline-block;
		width: 9px;
		height: 187px;
		overflow: hidden;
	}
	
	.shopee-countdown-timer__number__deca--second {
		-webkit-animation-name: second-digit;
		animation-name: second-digit;
		-webkit-animation-duration: 10s;
		animation-duration: 10s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--second {
		-webkit-animation-name: second-ten;
		animation-name: second-ten;
		-webkit-animation-duration: 60s;
		animation-duration: 60s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--minute {
		-webkit-animation-name: minute-digit;
		animation-name: minute-digit;
		-webkit-animation-duration: 600s;
		animation-duration: 600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--minute {
		-webkit-animation-name: minute-ten;
		animation-name: minute-ten;
		-webkit-animation-duration: 3600s;
		animation-duration: 3600s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__deca--hour {
		-webkit-animation-name: hour-digit;
		animation-name: hour-digit;
		-webkit-animation-duration: 36000s;
		animation-duration: 36000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__hexa--hour {
		-webkit-animation-name: hour-ten;
		animation-name: hour-ten;
		-webkit-animation-duration: 360000s;
		animation-duration: 360000s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	
	.shopee-countdown-timer__number__item {
		position: relative;
		width: 9px;
		height: 17px;
	}
	
	.shopee-countdown-timer__number__item svg {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 17px;
		height: 13px;
		fill: #fff;
		stroke: #fff;
		overflow: visible;
	}
	
	.colon-dot {
		position: absolute;
		border-radius: 100%;
		width: 3px;
		height: 3px;
		top: 10%;
		left: 0;
		background: currentColor;
	}
	
	.colon-dot__wrapper {
		width: 100%;
		height: 50%;
		position: relative;
	}
	
	.flash-sale-logo {
		display: inline-block;
	}
	
	.flash-sale-logo--white,
	.hO9GFq {
		color: #fff;
	}
	
	.hO9GFq {
		display: flex;
		align-items: center;
		margin-top: .625rem;
		height: 36px;
		padding: 0 20px;
		background-image: url(https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/4323ad4dc2b3c72d0474d51f20fb83e8.jpg), linear-gradient(-90deg, #f0451e 9%, #f32424 96%);
	}
	
	.FteIo2 {
		text-transform: uppercase;
		margin-right: .625rem;
		font-weight: lighter;
	}
	
	._8tjJ2v {
		width: 17px;
		height: 17px;
		margin-left: auto;
		margin-right: 6px;
	}
	
	.Kg2R-S {
		font-size: 1rem;
		text-decoration: line-through;
		color: #929292;
		margin-right: 10px;
	}
	
	.X0xUb5 {
		font-size: 1.875rem;
		font-weight: 500;
		color: #ee4d2d;
	}
	
	.theme--ofs .X0xUb5 {
		color: #d0011b;
	}
	
	.\+1IO\+x {
		margin-left: 15px;
		font-size: .75rem;
		color: #fff;
		text-transform: uppercase;
		background: #ee4d2d;
		border-radius: 2px;
		padding: 2px 4px;
		font-weight: 600;
		line-height: 1;
		white-space: nowrap;
	}
	
	.theme--ofs .\+1IO\+x {
		background-color: #d0011b;
	}
	
	._9GxTz3 {
		flex-wrap: wrap;
		width: 625px;
		flex-basis: 625px;
		min-height: 1.875rem;
	}
	
	.LR5fT5 {
		padding: 15px 20px;
		background: #fafafa;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/voucher/573.6f2c85ba2352c7f1c9c0.legacy.css */
	
	.stardust-icon {
		stroke: currentColor;
		fill: currentColor;
		width: 1em;
		height: 1em;
	}
	
	
	/*! CSS Used from: https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/1545.de836f8ab95af45532cc.legacy.css */
	
	.flex {
		display: flex;
	}
	
	
	/*! CSS Used keyframes */
	
	@-webkit-keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes second-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes second-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		15% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		31.66% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		48.33% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		65% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		81.66% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		98.33% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes minute-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.833% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.833% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.833% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.833% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.833% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.833% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.833% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.833% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.833% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.833% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@keyframes minute-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.638888% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		16.666666% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.305555% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		33.333333% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		49.972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		50% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.638888% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		66.666666% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.305555% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		83.333333% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		99.972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		to {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
	}
	
	@-webkit-keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-digit {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.9972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.9972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.9972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.9972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.9972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.9972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.9972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.9972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.9972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.9972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@-webkit-keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}
	
	@keyframes hour-ten {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		9.99972222% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		10% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		19.99972222% {
			-webkit-transform: translateY(-17px);
			transform: translateY(-17px);
		}
		20% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		29.99972222% {
			-webkit-transform: translateY(-34px);
			transform: translateY(-34px);
		}
		30% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		39.99972222% {
			-webkit-transform: translateY(-51px);
			transform: translateY(-51px);
		}
		40% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		49.99972222% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}
		50% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		59.99972222% {
			-webkit-transform: translateY(-85px);
			transform: translateY(-85px);
		}
		60% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		69.99972222% {
			-webkit-transform: translateY(-102px);
			transform: translateY(-102px);
		}
		70% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		79.99972222% {
			-webkit-transform: translateY(-119px);
			transform: translateY(-119px);
		}
		80% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		89.99972222% {
			-webkit-transform: translateY(-136px);
			transform: translateY(-136px);
		}
		90% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		99.99972222% {
			-webkit-transform: translateY(-153px);
			transform: translateY(-153px);
		}
		to {
			-webkit-transform: translateY(-170px);
			transform: translateY(-170px);
		}
	}.goods-detail .basic-info-wrap .item-block {
    background: #fff0;
    padding: 10px 0;
    position: relative;
}.goods-detail .basic-info-wrap .item-line dt {
    display: inline-block;
    width: 76px;
    height: 35px;
    line-height: 35px;
    text-align: justify;
    padding-left: 16px;
    font-size: 12px;
    margin-right: 16px;
    color: #838383;
    flex-shrink: 0;
}/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
*{-webkit-overflow-scrolling:touch;}
*{box-sizing:border-box;outline:none;}
p{margin:0;font-weight:400;}
::-moz-selection{background:#b3d4fc;text-shadow:none;}
::selection{background:#b3d4fc;text-shadow:none;}
@media print{
*,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
p{orphans:3;widows:3;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
p{margin-top:0;margin-bottom:1rem;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
p{orphans:3;widows:3;}
}
@media all{
*,*:before,*:after{box-sizing:inherit;}
::-webkit-scrollbar{background-color:rgba(24, 24, 24, 0.07);width:8px;height:8px;}
::-webkit-scrollbar-thumb{background-color:rgba(24, 24, 24, 0.3);border-radius:6px;-webkit-transition:background 200ms ease;transition:background 200ms ease;}
::-webkit-scrollbar-thumb:hover{background-color:#34495e;}
::-webkit-scrollbar-button{display:none;}
p{margin:0 0 10px;}
}
*,::after,::before{box-sizing:border-box;}
p{margin-top:0;margin-bottom:1rem;}
::-moz-focus-inner{padding:0;border-style:none;}
*{margin:0;padding:0;box-sizing:border-box;}
::-webkit-scrollbar{width:4px;height:4px;}
::-webkit-scrollbar-track{box-shadow:inset 0 0 4px #a7a7a7;border-radius:30px;}
::-webkit-scrollbar-thumb{background-color:#a7a7a7;border-radius:10px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
p{font-family:"Prompt", sans-serif;font-size:16px;font-weight:400;line-height:24px;color:#403c39;}
@media print{
*{color:#000000!important;}
}
*{-webkit-overflow-scrolling:touch;}
*{box-sizing:border-box;outline:none;}
::-moz-selection{background:#b3d4fc;text-shadow:none;}
::selection{background:#b3d4fc;text-shadow:none;}
@media print{
*,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
*{-webkit-overflow-scrolling:touch;}
*{box-sizing:border-box;outline:none;}
::-moz-selection{background:#b3d4fc;text-shadow:none;}
::selection{background:#b3d4fc;text-shadow:none;}
@media print{
*,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
}
*{-webkit-overflow-scrolling:touch;}
*{box-sizing:border-box;outline:none;}
::-moz-selection{background:#b3d4fc;text-shadow:none;}
::selection{background:#b3d4fc;text-shadow:none;}
@media print{
*,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
}
*,*::before,*::after{box-sizing:border-box;}
::-moz-focus-inner{padding:0;border-style:none;}
:focus{outline:initial;}
*,::after,::before{box-sizing:border-box;}
::-moz-focus-inner{padding:0;border-style:none;}
*{margin:0;padding:0;box-sizing:border-box;}
::-webkit-scrollbar{width:4px;height:4px;}
::-webkit-scrollbar-track{box-shadow:inset 0 0 4px #a7a7a7;border-radius:30px;}
::-webkit-scrollbar-thumb{background-color:#a7a7a7;border-radius:10px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
@media print{
*{color:#000000!important;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
*,*::before,*::after{box-sizing:border-box;}
::-moz-focus-inner{padding:0;border-style:none;}
:focus{outline:initial;}
*,::after,::before{box-sizing:border-box;}
::-moz-focus-inner{padding:0;border-style:none;}
*{margin:0;padding:0;box-sizing:border-box;}
::-webkit-scrollbar{width:4px;height:4px;}
::-webkit-scrollbar-track{box-shadow:inset 0 0 4px #a7a7a7;border-radius:30px;}
::-webkit-scrollbar-thumb{background-color:#a7a7a7;border-radius:10px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
@media print{
*{color:#000000!important;}
}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
/*! CSS Used from: Embedded */
p{margin:0;padding:0;}
i{font-style:normal;}
.count-down span{display:inline-block;width:22px;height:22px;line-height:22px;text-align:center;background:#383838;color:#ffffff;border-radius:2px;}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media all{
*,*:before,*:after{box-sizing:border-box;}
}
@media all{
*:not(body){-webkit-tap-highlight-color:transparent;-ms-overflow-style:none;scrollbar-width:none;}
div{border:0;margin:0;padding:0;}
::-webkit-scrollbar{display:none;}
::-webkit-scrollbar-button{display:none;}
::-webkit-scrollbar-track{background:none;}
::-webkit-scrollbar-thumb{background:#B7B7B7;min-height:40px;border-radius:6px;display:none;}
:focus{outline:0;}
}
@media all{
*,::after,::before{box-sizing:border-box;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
@media screen{
div,p{margin:0;padding:0;}
}
i{font-style:normal;}
@media screen{
div,p{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
i{font-style:normal;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
@media screen{
div,p{margin:0;padding:0;}
}
i{font-style:normal;}
@media screen{
div,p{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
i{font-style:normal;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
.module-20-time span{min-width:22px;height:26px;border:2px solid #fff;border-radius:6px;font-size:14px;font-weight:400;font-weight:initial;background-color:#333;color:#fff;display:inline-block;line-height:28px;text-align:center;padding:0 2px;}
.module-20-Head .module-20-time{display:inline-block;margin:5px 22px 0px;vertical-align:top;}
@media (max-width: 1440px){
.module-20-Head .module-20-time{margin-top:1px;}
}
@media screen{
div{margin:0;padding:0;}
}
@media screen{
div{margin:0;padding:0;}
}
*{padding:0;margin:0;box-sizing:content-box;}
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
/*! CSS Used fontfaces */
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");unicode-range:U+1F00-1FFF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");unicode-range:U+0370-03FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:"Prompt";font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
.module-20-time span {
    min-width: 22px;
    height: 26px;
    border: 1px solid #fff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    font-weight: initial;
    background-color: #333;
    color: #fff;
    display: inline-block;
    line-height: 28px;
    text-align: center;
    padding: 0 2px;
}.goods-detail .detail-wrap .goods-tab {
    margin-left: 0px;
}/*! CSS Used from: https://element-plus.org/assets/style.c017d434.css */
p{font-size:inherit;}
p{line-height:1.8;}
p:first-child{margin-top:0;}
p:last-child{margin-bottom:0;}
.el-empty{--el-empty-padding:40px 0;--el-empty-image-width:160px;--el-empty-description-margin-top:20px;--el-empty-bottom-margin-top:20px;--el-empty-fill-color-0:var(--el-color-white);--el-empty-fill-color-1:#fcfcfd;--el-empty-fill-color-2:#f8f9fb;--el-empty-fill-color-3:#f7f8fc;--el-empty-fill-color-4:#eeeff3;--el-empty-fill-color-5:#edeef2;--el-empty-fill-color-6:#e9ebef;--el-empty-fill-color-7:#e5e7e9;--el-empty-fill-color-8:#e0e3e9;--el-empty-fill-color-9:#d5d7de;display:flex;justify-content:center;align-items:center;flex-direction:column;text-align:center;box-sizing:border-box;padding:var(--el-empty-padding);}
.el-empty__image{width:var(--el-empty-image-width);}
.el-empty__image svg{color:var(--el-svg-monochrome-grey);fill:currentColor;width:100%;height:100%;vertical-align:top;}
.el-empty__description{margin-top:var(--el-empty-description-margin-top);}
.el-empty__description p{margin:0;font-size:var(--el-font-size-base);color:var(--el-text-color-secondary);}
*,:before,:after{box-sizing:border-box;}
p{margin:1rem 0;line-height:1.7;}
*{scrollbar-color:var(--el-scrollbar-bg-color) var(--el-fill-color-light);}
::-webkit-scrollbar{width:6px;}
::-webkit-scrollbar-track{border-radius:10px;}
::-webkit-scrollbar-thumb{background-color:#0003;border-radius:10px;transition:all .2s ease-in-out;}
::-webkit-scrollbar-thumb:hover{cursor:pointer;background-color:#0000004d;}
[fill~="none"]{fill:none;}.module-20-time span {
    min-width: 30px;
    height: 26px;
    border: 1px solid #fff;
    border-radius: 1px;
    font-size: 14px;
    font-weight: 500;
    font-weight: initial;
    background-color: #333;
    color: #fff;
    display: inline-block;
    line-height: 25.4px;
    text-align: center;
    padding: 0 2px;
}.count-down span{display:inline-block !important;
	width:22px !important;
	height:22px !important;
	line-height:22px !important;
	text-align:center !important;
	background:#383838 !important;
	color:#ffffff !important;
	border-radius:2px !important; }
	.vjs-icon-volume-high, .video-js .vjs-mute-control .vjs-icon-placeholder {
	    font-family: VideoJS !important;
	 
	}
	.vjs-icon-play, .video-js .vjs-play-control .vjs-icon-placeholder, .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
	    font-family: VideoJS !important;
	
	}.vjs-icon-fullscreen-enter, .video-js .vjs-fullscreen-control .vjs-icon-placeholder {
    font-family: VideoJS!important;

}
</style>
